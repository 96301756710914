.footer {
  width: 100%;
  margin-top: 3em;
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .version_text {
    font-size: 10px;
    color: #efefef;
    &:hover {
      color: #222;
    }
  }

  a {
    font-size: 14px;
    color: #222;
    text-decoration: none;

    &:hover {
      color: #0f65ef;
    }
  }

  span {
    margin: 0 10px;
    cursor: default;
  }

  .consent_button {
    margin-top: 1.5em;
    padding: .5rem .875rem;
    background-color: #0F65EF;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    font: inherit;

    &:hover {
      opacity: 0.7;
    }
  }

  :global(#ot-sdk-btn.ot-sdk-show-settings) {
    &.gdpr_button {
      @extend .consent_button;
      background-color: #fff;
      color: #0F65EF;
      border: 1px solid #0F65EF;
    }
  }

  :global(#ot-sdk-btn.ot-sdk-show-settings) {
    &.ccpa_button {
      @extend .consent_button;
    }
  }
}

// Dark Mode
@media (prefers-color-scheme: dark) {
  .footer {
    a, span {
      color: #E1E1E3;
    }
    :global(#ot-sdk-btn.ot-sdk-show-settings) {
      &.ccpa_button {
        background-color: #4E8DF4;
      }
    }
  }
}
