@import "solid-helpers/variables";

.main {
  width: 900px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  > p {
    width: 100%;
    margin-left: 10px;
    font-size: 16px;
    color: #666666;
  }

  > h2 {
    font-size: 16px;
    font-weight: 800;
    margin-top: 15px;
    margin-bottom: 4px;
    margin-left: 6px;
    width: 100%;
  }

  &.force-3-column {
    justify-content: space-between;
    max-width: calc(95% - 8px);
    width: 860px;

    & > h2 {
      margin-left: 0px;
    }

    & > [class^="post__"] {
      margin: 4px 0px;
      flex-basis: calc(33% - 4px) !important;
    }

    & > [class^="Ad__"] {
      flex-basis: 100%;
      text-align: center;
    }
  }
}

.feedPage {
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;
}

.modalOpen {
  background: rgb(34, 34, 34, 75%);
}

.modal {
  position: absolute;
  inset: 32px 16px;
  margin: auto;
  max-width: 23.25rem;
  max-height: 45rem;
  border: 1px solid $fill-gray-light;
  background: $fill-white;
  overflow: auto;
  border-radius: 8px;
  outline: none;
  padding: 20px;
  z-index: -1;
}

.tapTarget {
    &:hover {
      cursor: pointer;
    }
  height: 36px;
  width: 36px;
  z-index: 1;
  float: right;
}

.icon {
  height: 20px;
  width: 20px;
  float: right;
  fill: $fill-gray;
}

.modalContents {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.modalImage {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  z-index: -1;
}

.multilink {
  color: $fill-blue;
  text-decoration: none;
  text-align: center;
  padding: 12px 25px;
  margin: 16px 0;
  border: 0.5px solid $fill-blue;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
