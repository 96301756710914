@import "solid-helpers/variables";

.page-bg {
  background: #efefef;
}

.header {
  padding: 20px 0;
  margin: 0 auto;
  width: 900px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.huffpost-logo {
  width: 200px;
  margin: 36px 0 12px 0;
}

.complex-logo {
  width: 200px;
  margin: 40px 0 46px 0;
}

.pubhub-logo {
  height: 46px;
  width: 200px;
  margin: 60px 0 30px 0;
}

.bigred-logo {
  width: 200px;
  margin: 60px 0 30px 0;
  > g {
    > path {
      fill: $fill-buzzfeed;
    }
  }
}

.grid-item {
  position: relative;
  height: auto;
  flex-basis: calc(50% - 20px);  // 2 wide grid for mobile
  margin: 10px;
  overflow: hidden;
  transition: 100ms;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    background-color: #fff;
  }
}

.grid-content {
  position: absolute;
  height: 101%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    opacity: 0.5;
  }
}

.account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    width: 90%;
    height: auto;
    border-radius: 50%;
  }
}


@media (min-width: 750px) {
  .header {
    > p {
      font-size: 1.17em;
    }
  }
  .grid-item {
    flex-basis: calc(33.33% - 20px);  // 3 wide grid for desktop
  }
}
